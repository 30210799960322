import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { isPhone } from "@/libs/wechat";

// 创建实例
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  // 设置请求超时60秒
  headers: {
    post: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json; charset=UTF-8'
    },
    get: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  responseType: 'json'
});

// 请求拦截
service.interceptors.request.use(function (config) {
  var token = !store.getters.token ? sessionStorage.getItem('token') : store.getters.token;
  if (token) config.headers['Authori-zation'] = token;
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  return config;
}, function (error) {
  console.error(error);
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (_ref) {
  var data = _ref.data;
  // console.log(data)
  if (data.code === 401) {
    // to re-login
    Message.error('无效的会话，或者登录已过期，请重新登录。');
    location.href = '/login';
  } else if (data.code === 403) {
    Message.error('没有权限访问。');
  } else if (data.code !== 200) {
    // 移动端
    if (isPhone()) return Promise.reject(data || 'Error');
    Message.error(data.message || 'Error');
    return Promise.reject();
  } else {
    return data.data;
  }
}, function (error) {
  Message.error(error.message);
  console.error('Error ==> ' + error);
  return Promise.reject(error);
});
export default service;
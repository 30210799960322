import Layout from '@/layout';
var appSettingRouter = {
  path: '/appSetting',
  component: Layout,
  redirect: '/appSetting/publicAccount/wxMenus',
  name: 'appSetting',
  meta: {
    title: '应用',
    icon: 'clipboard'
  },
  children: [{
    path: 'publicAccount',
    name: 'publicAccount',
    component: function component() {
      return import('@/views/appSetting/wxAccount');
    },
    meta: {
      title: '公众号',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'wxMenus',
      component: function component() {
        return import('@/views/appSetting/wxAccount/wxMenus');
      },
      name: 'wxMenus',
      meta: {
        title: '微信菜单',
        icon: ''
      }
    }, {
      path: 'wxReply',
      component: function component() {
        return import('@/views/appSetting/wxAccount/reply/index');
      },
      name: 'wxReply',
      meta: {
        title: '自动回复',
        icon: ''
      },
      children: [{
        path: 'follow',
        component: function component() {
          return import('@/views/appSetting/wxAccount/reply/follow');
        },
        name: 'wxFollow',
        meta: {
          title: '微信关注回复',
          icon: ''
        }
      }, {
        path: 'keyword',
        component: function component() {
          return import('@/views/appSetting/wxAccount/reply/keyword');
        },
        name: 'wxKeyword',
        meta: {
          title: '关键字回复',
          icon: ''
        }
      }, {
        path: 'replyIndex',
        component: function component() {
          return import('@/views/appSetting/wxAccount/reply/follow');
        },
        name: 'wxReplyIndex',
        meta: {
          title: '无效关键词回复',
          icon: ''
        }
      }, {
        path: 'keyword/save/:id?',
        name: 'wechatKeywordAdd',
        meta: {
          title: '关键字添加',
          noCache: true,
          activeMenu: "/appSetting/publicAccount/wxReply/keyword"
        },
        hidden: true,
        component: function component() {
          return import('@/views/appSetting/wxAccount/reply/follow');
        }
      }]
    }]
  }]
};
export default appSettingRouter;
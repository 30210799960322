function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Confirm as confirm, Alert as alert, Toast as toast, Notify as notify, Loading as loading } from "vue-ydui/dist/lib.rem/dialog";
var dialog = {
  confirm: confirm,
  alert: alert,
  toast: toast,
  notify: notify,
  loading: loading
};
var icons = {
  error: "操作失败",
  success: "操作成功"
};
Object.keys(icons).reduce(function (dialog, key) {
  dialog[key] = function (mes) {
    var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new Promise(function (resolve) {
      toast(_objectSpread({
        mes: mes || icons[key],
        timeout: 1000,
        icon: key,
        callback: function callback() {
          resolve();
        }
      }, obj));
    });
  };
  return dialog;
}, dialog);
dialog.message = function () {
  var mes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "操作失败";
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve) {
    toast(_objectSpread({
      mes: mes,
      timeout: 1000,
      callback: function callback() {
        resolve();
      }
    }, obj));
  });
};
dialog.validateError = function () {
  validatorDefaultCatch.apply(void 0, arguments);
};
export function validatorDefaultCatch(err) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "message";
  console.log(err);
  return dialog[type](err.errors[0].message);
}
export default dialog;
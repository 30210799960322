//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import elementIcons from './element-icons';
export default {
  name: 'Index',
  data: function data() {
    return {
      elementIcons: elementIcons,
      iconVal: '',
      modals2: false,
      list: []
    };
  },
  mounted: function mounted() {
    this.list = this.elementIcons;
  },
  methods: {
    generateElementIconCode: function generateElementIconCode(symbol) {
      return "<i class=\"el-icon-".concat(symbol, "\" />");
    },
    handleClipboard: function handleClipboard(text, event, n) {
      this.iconChange(n);
      //  clipboard(text, event)
    },
    // 搜索
    upIcon: function upIcon(n) {
      var arrs = [];
      for (var i = 0; i < this.elementIcons.length; i++) {
        if (this.elementIcons[i].indexOf(n) !== -1) {
          arrs.push(this.elementIcons[i]);
          this.list = arrs;
        }
      }
    },
    iconChange: function iconChange(n) {
      this.$emit('getIcon', n);
      this.$msgbox.close();
    }
  }
};
export default function modalIcon(callback) {
  var _this = this;
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    _this.$msgbox({
      title: '菜单图标',
      customClass: 'upload-form',
      closeOnClickModal: false,
      showClose: false,
      message: h('div', {
        class: 'common-form-upload'
      }, [h('iconFrom', {
        on: {
          getIcon: function getIcon(n) {
            callback(n);
          }
        }
      })]),
      showCancelButton: false,
      showConfirmButton: false
    }).then(function () {
      resolve();
    }).catch(function () {
      reject();
      _this.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThemePicker from '@/components/ThemePicker';
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {
      sideTheme: this.$store.state.settings.sideTheme,
      routers: this.$store.state.permission.routes
    };
  },
  computed: {
    theme: {
      get: function get() {
        return this.$store.state.settings.theme;
      }
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
    },
    handleTheme: function handleTheme(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideTheme',
        value: val
      });
      this.sideTheme = val;
    },
    saveSetting: function saveSetting() {
      this.$modal.loading("正在保存到本地，请稍候...");
      //将设置写入缓存
      this.$cache.local.setJSON('layout-setting', {
        "topNav": this.topNav,
        "tagsView": this.tagsView,
        "fixedHeader": this.fixedHeader,
        "sidebarLogo": this.sidebarLogo,
        "dynamicTitle": this.dynamicTitle,
        "sideTheme": this.sideTheme,
        "theme": this.theme,
        "navIcon": this.navIcon
      });
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    resetSetting: function resetSetting() {
      this.$modal.loading("正在清除设置缓存并刷新，请稍候...");
      this.$cache.local.remove("layout-setting");
      setTimeout("window.location.reload()", 1000);
    }
  }
};
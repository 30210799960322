//
//
//
//
//
//
//
//
//
//
//
//
//

import articleList from '../index.vue';
export default {
  name: 'CouponFrom',
  components: {
    articleList: articleList
  },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      handle: '',
      keyNum: 0,
      couponId: [],
      userIds: ''
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getArticle: function getArticle(couponObj) {
      this.callback(couponObj);
      this.visible = false;
    }
  }
};
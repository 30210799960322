import request from '@/utils/request';
export function configCheckUnique(pram) {
  var data = {
    name: pram.name
  };
  return request({
    url: '/admin/system/config/check',
    method: 'GET',
    params: data
  });
}
export function configDelete(pram) {
  var data = {
    id: pram.id
  };
  return request({
    url: '/admin/system/config/delete',
    method: 'GET',
    params: data
  });
}
export function configInfo(pram) {
  var data = {
    formId: pram.id
  };
  return request({
    url: '/admin/system/config/info',
    method: 'GET',
    params: data
  });
}
export function configList(pram) {
  var data = {
    page: pram.page,
    limit: pram.limit
  };
  return request({
    url: '/admin/system/config/list',
    method: 'GET',
    params: data
  });
}
export function configSave(pram) {
  var data = {
    systemConfigRequest: {
      desc: pram.desc,
      groupId: pram.groupId,
      info: pram.info,
      name: pram.name,
      pid: pram.pid,
      status: pram.status,
      type: pram.type,
      value: pram.value // value 存储表单配置数据，其他的参数来自于父级数据 justForAPI
    }
  };
  return request({
    url: '/admin/system/config/save',
    method: 'POST',
    params: data
  });
}
export function configSaveForm(pram) {
  return request({
    url: '/admin/system/config/save/form',
    method: 'POST',
    data: pram
  });
}
export function configUpdate(pram) {
  var data = {
    id: pram.id,
    systemConfigRequest: pram.systemConfigRequest
  };
  return request({
    url: '/admin/system/config/update',
    method: 'POST',
    params: data
  });
}
export function configSaveUniq(pram) {
  var data = {
    key: pram.key,
    value: pram.value
  };
  return request({
    url: '/admin/system/config/saveuniq',
    method: 'POST',
    params: data
  });
}
export function configGetUniq(pram) {
  var data = {
    key: pram.key
  };
  return request({
    url: '/admin/system/config/getuniq',
    method: 'GET',
    params: data
  });
}
//会员过滤器

/**
 * 等级
 */
export function levelFilter(status) {
  if (!status) return '普通会员';
  var arrayList = JSON.parse(localStorage.getItem('levelKey'));
  var array = arrayList.filter(function (item) {
    return status === item.id;
  });
  return array.length ? array[0].name : '普通会员';
}

/**
 * 用户类型
 */
export function typeFilter(status) {
  var statusMap = {
    'wechat': '微信用户',
    'routine': '小程序用户',
    'h5': 'H5用户'
  };
  return statusMap[status];
}

/**
 * 用户类型
 */
export function filterIsPromoter(status) {
  var statusMap = {
    true: '推广员',
    false: '普通用户'
  };
  return statusMap[status];
}
export function licenseApplyFilter(status) {
  if (!status) return '未审核';
  if (status == 1) return '已通过';
  return '未通过';
}
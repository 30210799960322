var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("i", {
      staticClass: "iconfont iconios-qr-scanner",
      staticStyle: { "font-size": "20px" },
      on: { click: _vm.click },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
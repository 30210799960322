//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "index",
  data: function data() {
    return {
      startTime: '',
      endTime: ''
    };
  },
  props: {
    value: {}
  },
  beforeMount: function beforeMount() {
    // 接收 v-model 数据
    if (this.value) {
      this.startTime = this.value.split(',')[0];
      this.endTime = this.value.split(',')[1];
    }
  },
  watch: {
    startTime: function startTime(val) {
      this.$emit('input', [val, this.endTime].join(','));
    },
    endTime: function endTime(val) {
      this.$emit('input', [this.startTime, val].join(','));
    }
  }
};
import Layout from '@/layout';
var financialRouter = {
  path: '/financial',
  component: Layout,
  redirect: '/financial',
  name: 'Financial',
  meta: {
    title: '财务',
    icon: 'clipboard'
  },
  children: [{
    path: 'commission',
    component: function component() {
      return import('@/views/financial/index');
    },
    name: 'Commission',
    meta: {
      title: '财务操作',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'template',
      component: function component() {
        return import('@/views/financial/commission/withdrawal/index');
      },
      name: 'commissionTemplate',
      meta: {
        title: '提取肽豆',
        icon: ''
      }
    }]
  }, {
    path: 'record',
    component: function component() {
      return import('@/views/financial/record/index');
    },
    name: 'financialRecord',
    meta: {
      title: '财务记录',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'charge',
      component: function component() {
        return import('@/views/financial/record/charge/index');
      },
      name: 'Charge',
      meta: {
        title: '充值记录',
        icon: ''
      }
    }, {
      path: 'monitor',
      component: function component() {
        return import('@/views/financial/record/monitor/index');
      },
      name: 'Monitor',
      meta: {
        title: '资金监控',
        icon: ''
      }
    }]
  }, {
    path: 'bonus',
    component: function component() {
      return import('@/views/financial/bonus/index');
    },
    name: 'financialBonus',
    meta: {
      title: '业绩分红',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'service',
      component: function component() {
        return import('@/views/financial/bonus/service');
      },
      name: 'bonusService',
      meta: {
        title: '服务站奖励',
        icon: ''
      }
    }, {
      path: 'group',
      component: function component() {
        return import('@/views/financial/bonus/group');
      },
      name: 'bonusGroup',
      meta: {
        title: '团长奖励',
        icon: ''
      }
    }]
  }, {
    path: 'brokerage',
    component: function component() {
      return import('@/views/financial/brokerage/index');
    },
    name: 'Brokerage',
    meta: {
      title: '肽豆记录',
      icon: ''
    }
  }]
};
export default financialRouter;
var state = {
  logs: []
};
var mutations = {
  ADD_ERROR_LOG: function ADD_ERROR_LOG(state, log) {
    state.logs.push(log);
  },
  CLEAR_ERROR_LOG: function CLEAR_ERROR_LOG(state) {
    state.logs.splice(0);
  }
};
var actions = {
  addErrorLog: function addErrorLog(_ref, log) {
    var commit = _ref.commit;
    commit('ADD_ERROR_LOG', log);
  },
  clearErrorLog: function clearErrorLog(_ref2) {
    var commit = _ref2.commit;
    commit('CLEAR_ERROR_LOG');
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};